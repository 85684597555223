export const LOGIN_CONST: any = {
  INVALID_CREDS: 'The email or password is incorrect',
  LOGIN_DEFAULT_ERROR: 'Server error: Unable to login',
};

export const ALERT_BANNER: any = {
  GET_ALL_ALERT_BANNER_ERROR: 'Unable to fetch alert banner list, please try again later.',
  END_DATE_CAN_NOT_LESS_THAN_START_DATE: 'End date cannot be less than start date.',
}

export const DASHBOARD: any = {
  WIDGET_ERROR: 'There was an error while fetching widgets data',
  CHART_ERROR: 'There was an error while charts data',
};

export const USERS: any = {
  DOWNGRADE_ERROR: 'There was an error while fetching Users',
  DOWNGRADE_SUCCESS: 'Successfully downgraded',
  USERS_ERROR: 'There was an error while fetching users',
  SEARCH_USERS_ERROR: 'There was an error while Searching Users',
};

export const ANALYTICS: any = {
  ANALYTICS_ERROR: 'There was an error while fetching analytics data',
  SEARCH_ANALYTICS_ERROR: 'There was an error while Searching analytics data',
};
