import { NgClass, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, inject, OnDestroy, OnInit, signal, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { Router, RouterModule, RouterOutlet } from '@angular/router';
import { FuseLoadingBarComponent } from '@fuse/components/loading-bar';
import { startWith, Subject, Subscription, takeUntil } from 'rxjs';
import { MENU } from './menu';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'core/services/authentication.service';
import { Apollo } from 'apollo-angular';
import { partnerDetail } from 'core/services/graphql/auth/auth.query';
import { MatMenuModule } from '@angular/material/menu';
import { TextFieldModule } from '@angular/cdk/text-field';
import { FormControl, FormsModule, ReactiveFormsModule, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatOptionModule } from '@angular/material/core';
import { MatCalendar, MatCalendarCellClassFunction, MatDatepicker, MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { UtilityService } from 'core/services/utility.service';
import * as moment from 'moment-timezone';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import { ALERT_BANNER } from 'constants/messages.constants';
import {default as _rollupMoment} from 'moment';


import { SwalMsgService } from 'core/services/swal.service';
export const MY_FORMATS = {
    parse: {
      dateInput: 'MM/YYYY',
    },
    display: {
      dateInput: 'MM/YYYY',
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY',
    },
  };
@Component({
    selector: 'classy-layout',
    templateUrl: './classy.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        FuseLoadingBarComponent,
        NgIf,
        NgFor,
        MatIconModule,
        MatButtonModule,        
        RouterModule,
        NgbModule,    
        MatMenuModule,
        MatDividerModule,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        TextFieldModule,
        ReactiveFormsModule,
        MatButtonToggleModule,
        MatSelectModule,
        MatOptionModule,
        MatChipsModule,
        MatDatepickerModule
    ],
    providers: [
        {
          provide: DateAdapter,
          useClass: MomentDateAdapter,
          deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        },
    
        {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
      ],
      // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClassyLayoutComponent implements OnInit, OnDestroy {
    readonly datePickerHeader = DatePickerHeader;
    minDate: Date;
    currentDate: Date;
    startDate = new FormControl();
    endDate = new FormControl();
    menuItems: any = MENU;
    isLoading: boolean;
    profileSubscription: Subscription;
    partnerProfile: any = {};
    dateRange :any = {
        start_date: '', 
        end_date:''
    };
    constructor(
        private _router: Router,
        private authService: AuthenticationService,
        private apollo: Apollo,
        public utilityService:UtilityService,
         private swal: SwalMsgService,
         private cdr: ChangeDetectorRef
    ) {
        this.getProfileDetails();
    }

    get currentYear(): number {
        return  moment().tz(this.partnerProfile.timezone).year();
    }
    dateClass: MatCalendarCellClassFunction<Date> = (cellDate, view) => {      
      const timeZone = this.partnerProfile.timezone;
      const convertibleDate = moment(cellDate).tz(timeZone, true);    
      if (view === 'multi-year') {
        const currentYear = moment().tz(timeZone).year(); 
        const getFullYear = convertibleDate.year(); 
        if (getFullYear > currentYear || getFullYear < 2024) {
          return 'remove-years';
        }
      } else if (view === 'year') {
        const currentDate = moment().tz(timeZone); 
        if (convertibleDate.isAfter(currentDate)) {
          return 'remove-years';
        }
      }
    
      return '';
    };
    chosenYearHandler(normalizedYear: moment.Moment, type: string) {      
      const ctrlValue = (type === 'startDate') 
          ? moment(this.startDate.value).tz(this.partnerProfile.timezone) 
          : moment(this.endDate.value).tz(this.partnerProfile.timezone);

      ctrlValue.year(normalizedYear.year());
      if (type === 'startDate') {
          this.startDate.setValue(ctrlValue);
      } else {
          if (normalizedYear.year() === this.currentYear) {              
              this.endDate.setValue(moment().tz(this.partnerProfile.timezone).subtract(1, 'days'));
          } else {
              ctrlValue.month(11).date(31).set({ hour: 23, minute: 59, second: 0 });
              this.endDate.setValue(ctrlValue);
          }
      }
      this.updateDateRange();
  }
  
    
  chosenMonthHandler(normalizedMonth: moment.Moment, datepicker: MatDatepicker<moment.Moment>, type: string) {    
    const ctrlValue = (type === 'startDate') 
        ? moment(this.startDate.value).tz(this.partnerProfile.timezone) 
        : moment(this.endDate.value).tz(this.partnerProfile.timezone);
  
    ctrlValue.month(normalizedMonth.month());

    if (type === 'startDate') {
        ctrlValue.date(1);
        this.startDate.setValue(ctrlValue);
    } else {
        if (normalizedMonth.isSame(moment(), 'month')) {
            this.endDate.setValue(moment().tz(this.partnerProfile.timezone).subtract(1, 'days'));
        } else {
            ctrlValue.date(ctrlValue.daysInMonth());
            this.endDate.setValue(ctrlValue);
        }
    }
    this.updateDateRange();
    datepicker.close();
}

    

    async ngOnInit() {
      
      this.startDate.valueChanges.subscribe(value => {          
        this.updateEndDateMinDate('startDate');
      });
      this.endDate.valueChanges.subscribe(value => {
        this.updateEndDateMinDate('endDate');
      });
      
    }
    updateEndDateMinDate(type) {
      const startDate = this.startDate.value;      
      const endDate = this.endDate.value;
      if(startDate && endDate){
        if(endDate < startDate){
          this.swal.showErrorToast(ALERT_BANNER.END_DATE_CAN_NOT_LESS_THAN_START_DATE);
        }
      }
    }
    async getProfileDetails(): Promise<void> {
        const partner_id = localStorage.getItem('partner_id');
        try {
            this.profileSubscription = this.apollo
                .query({
                    fetchPolicy: 'network-only',
                    query: partnerDetail,
                    variables: { _id: partner_id },
                })
                .subscribe((result: any) => {
                    this.partnerProfile = result['data'].partnerDetail.data;
                    this.currentDate = moment().tz(this.partnerProfile.timezone).subtract(1, 'days').toDate();                    
                    this.startDate.setValue(moment().tz(this.partnerProfile.timezone).startOf('month'))
                    this.endDate.setValue(moment().tz(this.partnerProfile.timezone).subtract(1, 'days'))                                      
                    this.minDate = moment('2024-01-01').toDate();  
                    this.dateRange = {
                      start_date:this.startDate.value.toString(),
                      end_date:this.endDate.value.toString()
                    }                         
                    this.utilityService.changeData(this.dateRange);            
                    this.authService.storeProfileData(this.partnerProfile);
                });
        } catch (error) {
            console.error('Error fetching profile details', error);
        }
    }

    signOut(): void {
        this.authService.storeProfileData(null);
        this.authService.storeUserData(null);
        this.authService.fnLogout();
        this._router.navigate(['/login']);
    }

    ngOnDestroy(): void {
        this.profileSubscription.unsubscribe();
    }
    updateDateRange() {
      if(this.startDate.value && this.endDate.value){
       this.dateRange = {
          start_date: this.startDate.value.toString(), 
          end_date: this.endDate.value.toString()
        };
        this.utilityService.changeData(this.dateRange);
      }
    }

    onInputFocus(inputType: string): void {
      const inputElement = document.querySelector(`input[formControlName=${inputType}]`) as HTMLInputElement;
      if (inputElement) {
          inputElement.style.cursor = 'pointer';  // Change cursor to pointer when input is focused
      }     
  }
  
  onInputBlur(inputType: string): void {
      const inputElement = document.querySelector(`input[formControlName=${inputType}]`) as HTMLInputElement;
      if (inputElement) {
          inputElement.style.cursor = 'text';  // Change cursor back to text when input is blurred
      }      
  }
  
}
@Component({
  selector: 'date-picker-header',
  standalone:true,
  styles: `
    .date-picker-header {
      display: flex;
      align-items: center;
      padding: 0.5em;
    }

    .date-picker-header-label {
      flex: 1;
      height: 1em;
      font-weight: 500;
      text-align: center;
    }
  `,
  template: `
    <div class="date-picker-header">
      <button mat-icon-button (click)="previousClicked('year')">
        <mat-icon>keyboard_double_arrow_left</mat-icon>
      </button>
      <!-- <button mat-icon-button (click)="previousClicked('month')">
        <mat-icon>keyboard_arrow_left</mat-icon>
      </button> -->
      <span class="date-picker-header-label">{{periodLabel()}}</span>
      <!-- <button mat-icon-button (click)="nextClicked('month')">
        <mat-icon>keyboard_arrow_right</mat-icon>
      </button> -->
      <button mat-icon-button (click)="nextClicked('year')">
        <mat-icon>keyboard_double_arrow_right</mat-icon>
      </button>
    </div>
  `,
  imports: [MatButtonModule, MatIconModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatePickerHeader<D> implements OnDestroy {
  private _calendar = inject<MatCalendar<D>>(MatCalendar);
  private _dateAdapter = inject<DateAdapter<D>>(DateAdapter);
  private _dateFormats = inject(MAT_DATE_FORMATS);

  private _destroyed = new Subject<void>();

  readonly periodLabel = signal('');

  constructor() {
    this._calendar.stateChanges.pipe(startWith(null), takeUntil(this._destroyed)).subscribe(() => {
      this.periodLabel.set(
        this._dateAdapter
          .format(this._calendar.activeDate, this._dateFormats.display.monthYearLabel)
          .toLocaleUpperCase(),
      );
    });
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }

  previousClicked(mode: 'month' | 'year') {

    this._calendar.activeDate =
      mode === 'month'
        ? this._dateAdapter.addCalendarMonths(this._calendar.activeDate, -1)
        : this._dateAdapter.addCalendarYears(this._calendar.activeDate, -1);
  }

  nextClicked(mode: 'month' | 'year') {
    let checkDate = this.checkDateRange(this._calendar.activeDate, mode)
    this._calendar.activeDate =
      mode === 'month'
        ? this._dateAdapter.addCalendarMonths(this._calendar.activeDate, checkDate)
        : this._dateAdapter.addCalendarYears(this._calendar.activeDate, checkDate);
    
  }

  checkDateRange(customDate , mode) {
    const customDateMoment = moment(customDate);
    const zoneName = customDateMoment.tz();
    const startDate = moment('2024-01-01');
    const currentDate = moment().tz(zoneName);    
    if(mode == 'month'){
      customDateMoment.add(1, 'months');
    }else{
      customDateMoment.add(1, 'years');
    }
    const isInRange = customDateMoment.isBetween(startDate, currentDate, null, '[]');
    const setValue = isInRange ? 1 : 0;
    return setValue;
  }
}

