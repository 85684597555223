import { Injectable } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Injectable({ providedIn: 'root' })
export class SwalMsgService {
  showErrorToast(title) {
    Swal.fire({
      toast: true,
      position: 'top-end',
      icon: 'error',
      title: title,
      showConfirmButton: false,
      timer: 4000
    });
  }

  showSuccessToast(title) {
    Swal.fire({
      toast: true,
      position: 'top-end',
      icon: 'success',
      title: title,
      showConfirmButton: false,
      timer: 4000
    });
  }

  showWarningToast(title) {
    Swal.fire({
      toast: true,
      position: 'top-end',
      icon: 'warning',
      title: title,
      showConfirmButton: false,
      timer: 4000
    });
  }

  showInfoToast(title) {
    Swal.fire({
      toast: true,
      position: 'top-end',
      icon: 'info',
      title: title,
      showConfirmButton: false,
      timer: 4000
    });
  }

  showMixedToast(title, icon) {
    return Swal.fire({
      toast: true,
      position: 'top-end',
      icon: icon,
      imageUrl: "./assets/images/loader-svg.svg",
      title: title,
      showConfirmButton: false,
      customClass: {
        image: 'alert-loader-swal'
      },
      timer: 4000
    });
  }

}