import { Injectable } from "@angular/core";
import { BehaviorSubject, fromEvent, Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class UtilityService {
  private isMobileDeviceSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public windowResizer: Observable<Event> = fromEvent(window, 'resize');
  private dataSource = new BehaviorSubject<string>('');
  currentData = this.dataSource.asObservable();

  constructor() { }

  async checkWindowSize() {
    if (window.innerWidth <= 768) {
      if (!this.isMobileDeviceSubject.value)
        this.isMobileDeviceSubject.next(true);
    }
    else {
      if (this.isMobileDeviceSubject.value)
        this.isMobileDeviceSubject.next(false);
    }
    return this.isMobileDeviceSubject.value;
  }

  get getIsMobileDevice(): Observable<boolean> {
    return this.isMobileDeviceSubject.asObservable();
  }
  changeData(data: string) {
    this.dataSource.next(data);
  }
  isValidJsonString(str) {    
    try {
        JSON.parse(str);
        return true; // The string is valid JSON
    } catch (e) {
        return false; // The string is not valid JSON
    }
  }
  isCheckTooManyError(str){
    return str.includes("Too many attempts")
  }
}