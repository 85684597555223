<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<div class="flex flex-col flex-auto w-full min-w-0">
    <div
        class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
        <a routerLink="/" class="navbar-brand">
            <img src="assets/images/logo/logo.png" class="logo-width" />
        </a>                            
        <div class="page-nav-wrapper d-flex w-100">                       
            <ul class="nav page-navigation container zero-right-padding pr-0 d-flex justify-content-end">     
                <li class="nav-item d-none d-md-block h-0">
                    <mat-form-field class="range-filter">
                        <input matInput 
                            [matDatepicker]="startDateRange" 
                            placeholder="Month and Year" 
                            [formControl]="startDate"
                            [min]="minDate" 
                            (focus)="onInputFocus('startDate')" 
                            (blur)="onInputBlur('startDate')"
                            (click)="startDateRange.open()"> <!-- Open on click -->
                        <mat-datepicker-toggle matSuffix [for]="startDateRange"></mat-datepicker-toggle>
                        <mat-datepicker #startDateRange [calendarHeaderComponent]="datePickerHeader"
                                        startView="multi-year"
                                        (yearSelected)="chosenYearHandler($event, 'startDate')"
                                        (monthSelected)="chosenMonthHandler($event, startDateRange, 'startDate')"
                                        panelClass="month-picker" [dateClass]="dateClass">
                        </mat-datepicker>
                        
                    </mat-form-field>
                </li>
                &nbsp;
                &nbsp;
                <li class="nav-item d-none d-md-block h-0">
                    <mat-form-field class="range-filter">
                        <input 
                            matInput 
                            [matDatepicker]="endDateRange" 
                            placeholder="Month and Year" 
                            [formControl]="endDate"
                            [min]="minDate"                            
                            (focus)="onInputFocus('endDate')" 
                            (blur)="onInputBlur('endDate')"
                            (click)="endDateRange.open()"> 
                        <mat-datepicker-toggle matSuffix [for]="endDateRange"></mat-datepicker-toggle>
                        <mat-datepicker #endDateRange [calendarHeaderComponent]="datePickerHeader"
                                        startView="multi-year"
                                        (yearSelected)="chosenYearHandler($event, 'endDate')"
                                        (monthSelected)="chosenMonthHandler($event, endDateRange, 'endDate')"
                                        panelClass="month-picker" [dateClass]="dateClass">
                        </mat-datepicker>                        
                    </mat-form-field>
                </li>                                
                <ng-container *ngFor="let item of menuItems">
                    <li class="nav-item menuItems d-none d-md-block" [routerLinkActive]="'active'"
                        [class.li-disabled]="ig_account_attached == false && item?.enable_menu == false">
                        <a class="nav-link" [routerLink]="item.link">
                            <mat-icon class="icon-size-5 link-icon" [svgIcon]="'feather:' + item.icon"></mat-icon>
                            <span class="menu-title ml-1">{{ item.label }}</span>
                        </a>
                    </li>
                </ng-container>
                <button mat-icon-button class="btn-menuItems" [matMenuTriggerFor]="profileActions">
                    <span class="relative">
                        <img class="w-8 h-8 rounded-full" [src]="partnerProfile.logo_mob ? partnerProfile.logo_mob : 'assets/images/avatars/chat_logo_placeholder.png'">
                    </span>
                </button>
                <button class="nav page-navigation d-md-none zero-right-padding pr-0 justify-content-end"
                    mat-icon-button [matMenuTriggerFor]="userActions">
                    <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
                </button>
            </ul>
            <mat-menu [xPosition]="'before'" #userActions="matMenu">
                <button mat-menu-item *ngFor="let item of menuItems" [routerLink]="item.link">
                    <mat-icon [svgIcon]="'feather:' + item.icon"></mat-icon>
                    <span>{{item.label}}</span>
                </button>
            </mat-menu>
            <mat-menu [xPosition]="'before'" #profileActions="matMenu">
                <button mat-menu-item>
                    <span class="flex flex-col leading-none">
                        <span>Signed in as</span>
                        <span class="mt-1.5 text-md font-medium">{{partnerProfile.username}}</span>
                    </span>
                </button>
                <mat-divider class="my-2"></mat-divider>
                <button mat-menu-item (click)="signOut()">
                    <mat-icon [svgIcon]="'heroicons_outline:arrow-right-on-rectangle'"></mat-icon>
                    <span>Log out</span>
                </button>
            </mat-menu>
        </div>
    </div>
    <!-- Content -->
    <div class="flex flex-col flex-auto">
        <router-outlet *ngIf="!isLoading"></router-outlet>
    </div>
</div>